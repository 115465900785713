import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FiInfo } from "react-icons/fi";
import { getParameterByName } from "../utils";

import Layout from "../components/layout"
import Seo from "../components/seo"
const siteRef = getParameterByName('ref');

const Terms = () => (
  <Layout>
    <Seo title="Terms Of Service" />
    <section className="bg-gray-100">
      <div className="max-w-4xl mx-auto py-12">
        <div className="bg-white shadow-xl rounded-md">
          <div className="px-10 py-6 bg-gradient-to-bl from-sky-500 to-blue-700 rounded-t-md">
            <h1 className="text-white text-3xl mb-0.5">Terms of Use</h1>
            <p className="text-white text-sm text-opacity-50"><span className="font-semibold">Last updated:</span> 26/05/2021</p>
          </div>
          <div className="prose px-10 py-8 max-w-full">
            {siteRef === "mockupclips" && (
              <div className="flex bg-gray-100 p-4 rounded-md text-sm"><FiInfo className="hidden md:block mr-1.5 mt-0.5 opacity-60" /><p style={{ margin: 0 }}>The <a href="https://mockupclips.com">mockupclips.com</a> website is a product of Edit Square LTD and carries the same terms of use as below:</p></div>
            )}
            <div>
              <ol className="list-dec main-terms">
                <li><span className="font-bold text-lg">About our Terms of Service</span>
                  <ol start={1} className="list-dec">
                    <li>These terms (the “Terms”) explain how you may use Edit Square’s services (the “Services”).</li>
                    <li>You should read these Terms carefully before signing up to the Services.</li>
                    <li>By using the Services, you agree to be bound by these Terms and the documents referred to in them.</li>
                    <li>If you are a consumer, consumer law usually allows you up to 14 days to change your mind and get a full refund for your purchase. You do not have this right for digital content such as the videos you create using the Edit Square Services. You acknowledge that once you commence rendering a video, you will be charged for it unless the render fails.</li>
                    <li>If you have any questions about these Terms, please contact us by&nbsp;e-mail at <a href="mailto:help@editsquare.com">help@editsquare.com</a>.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Definitions</span>
                  <table className="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Account Data”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={1}>means any personal data submitted by you for the purposes of setting up an account on the Platform in order to use the Services;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Authorised Users”&nbsp;</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={2}>means you, your employees, agents and independent contractors (as applicable) who you authorise to use the Platform and Services;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Confidential Information”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={3}>means information that is proprietary or confidential and is either clearly labelled as such or identified as confidential information;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Client Content”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={4}>means your video, audio, images, slide designs or other materials uploaded to the Platform by you or by an Authorised User for the purpose of using the Services;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Client Data”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={5}>means any personal data contained in the Client Content;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Credit”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={6}>means up to 60 (sixty) seconds of rendered high definition video;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Fees”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={7}>means the fees payable by you to Edit Square for the Services;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Free Preview”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={8}>means a water-marked, low resolution video for non-commercial purposes;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Paid Video”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={9}>means a high-resolution, water-mark free video made pursuant to a Subscription or on a Pay-per-video basis that can be used for commercial purposes;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Party”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={10}>means either us or you, and together referred to as the “Parties”;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Pay-per-video”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={11}>means your use of the Platform on a pay-per-video basis;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Platform”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={12}>means the online editing platform owned and operated by Edit Square that enables you and your Authorised Users to customise and build videos;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Platform Content”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={13}>means any video, audio, images, slide designs or other materials available for use on the Platform, but excluding any Client Content;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Services”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={14}>means the Subscription and/or Pay-per-video services provided by Edit Square to you under these Terms as more particularly described in Clause 3 below;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Subscription”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={15}>means your use of the Platform on a monthly subscription basis via the Starter Plan, Booster Plan, Professional Plan or Business Plan;</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“We”, “us”, “our” or “Edit Square”</td>
                        <td colSpan={1} rowSpan={1}>means Edit Square Ltd a company incorporated in England and Wales under number 09971898 whose registered office of which is at 71-75 Shelton Street, Covent Garden, London WC2H 9JQ;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“Working Day”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={16}>means a day which is not a Saturday, Sunday or Public Holiday in England; and</ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>“You” or “your”</td>
                        <td colSpan={1} rowSpan={1}>
                          <ol start={17}>means the customer (whether it is a company, partnership, sole trader, individual or other entity as the case may be).</ol>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <span className="font-bold text-lg">Services</span>
                  <ol start={1} className="list-dec">
                    <li>Edit Square owns and operates an online editing platform that enables you and your Authorised Users to customise and build videos.</li>
                    <li>The Services are offered on a Subscription or Pay-per-video basis as set out below.</li>
                    <u>Subscription</u>
                    <li>Where you opt to purchase a Subscription with us, you will purchase a number of monthly Credits for use on the Platform. The number of Credits will depend on which Subscription plan you select. If you use all your Credits for a month, you have the option to purchase more on a Pay-per-video basis (see below) or upgrade your Subscription plan. Unused Credits in a month will not roll into the subsequent month.</li>
                    <li>A Credit is worth up to 60 (sixty) seconds of rendered high definition video. For the avoidance of doubt, if a video of 80 seconds is created, that will cost you 2 Credits.</li>
                    <li>Please ensure you are happy with your video before commencing rendering under the Subscription payment plan. Once rendering has commenced, you will be charged for the relevant number of Credits and you have no right to reclaim the Credits unless the rendering fails.</li>
                    <u>Pay-per-video</u>
                    <li>Where you opt to use the Pay-per-video payment plan, we will charge you a fixed amount for the rendered high definition video you create.</li>
                    <li>Please check your Free Preview before proceeding to make a purchase via the Pay-per-video payment plan. Once you have started rendering your Paid Video, you have no right to cancel the payment unless the rendering fails.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Fees and Payment</span>
                  <ol start={1} className="list-dec">
                    <li>Fees for a Subscription plan are payable monthly in advance.</li>
                    <li>Fees for a video using the Pay-per-video basis shall be payable in advance of rendering.</li>
                    <li>Payment of Fees will be made by credit card via our secure payment system. Where you have opted for a Subscription plan, by submitting such payment information, you automatically authorise us to charge for your Subscription plan until it is cancelled. Should automatic billing fail to occur for any reason, we will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice. If payment is not made by the deadline date, we reserve the right to suspend access to your account and/or terminate these Terms with you. Except where required by law, all amounts payable are non-refundable.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Licences</span>
                  <ol start={1} className="list-dec">
                    <li>In consideration of the payment of the Fees, we hereby grant to you a non-exclusive, non-transferable licence to use the Platform for the purposes of receiving the Services. This licence shall be immediately revoked on expiry or termination of these Terms with you howsoever caused.</li>
                    <li>In consideration of the payment of the Fees, but subject to any claim for a refund and your compliance with your obligations under these Terms, we hereby grant you a perpetual, irrevocable, non-exclusive, non-transferable licence to use the Platform Content contained in a Paid Video you create on the Platform. For the avoidance of doubt, any music or other audio elements of the Platform Content used in a Paid Video are licensed to you for use with that Paid Video only and you shall receive no right to use such music or audio for any project or purpose other than the Paid Video.</li>
                    <li>Where you seek to use Client Content in conjunction with the Services, you hereby grant us a non-exclusive, non-transferable licence to use the Client Content for the purposes of producing a Free Preview, rendering a Paid Video and otherwise provided the Services to you.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Data Protection</span>
                  <ol start={1} className="list-dec">
                    <li>The processing of Account Data shall be carried out in accordance with our Privacy Policy (<a href="https://editsquare.com/privacy-policy/">editsquare.com/privacy-policy/</a>).</li>
                    <li>The processing of Client Data (if any) shall be carried out in accordance with the Schedule to these Terms (Data Processing Addendum).</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Our Obligations</span>
                  <ol start={1} className="list-dec">
                    <li>We confirm that the Services will be performed with reasonable skill and care.</li>
                    <li>We confirm that we have and will maintain all necessary licences, consents, and permissions necessary for the performance of our obligations under these Terms.</li>
                    <li>We do not warrant that the Services will be uninterrupted or error-free or that the Services will meet your expectations.</li>
                    <li>We are not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and you acknowledge that the Services and the Platform may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Your Obligations</span>
                  <ol className="list-dec">
                    <li>You warrant that you will:
          <ol start={1} className="lower-alpha">
                        <li>provide us with all necessary co-operation and information in relation to these Terms;&nbsp;</li>
                        <li>comply with all applicable laws and regulations with respect to its activities under these Terms;</li>
                        <li>carry out all other obligations set out in these Terms in a timely and efficient manner;</li>
                        <li>obtain and shall maintain all necessary licenses, consents, and permissions necessary to perform your obligations under these Terms; and</li>
                        <li>be solely responsible for procuring and maintaining your network connections and telecommunications links from your systems to our Platform.</li>
                      </ol>
                    </li>
                    <li>You warrant that you have sole responsibility for the legality, reliability, integrity, accuracy and quality of the Client Content.</li>
                    <li>You accept that you are responsible for any additional third party charges you may incur when using the Services, for example if you are charged for data for downloading a Paid Video on your device.</li>
                    <li>You agree that you shall not distribute or transmit any viruses, worms, time bombs, time locks, drop dead devices, traps or trap door devices onto the Platform. You hereby confirm that you are using market standard anti-virus software before registering an account, accessing the Platform or using the Services.</li>
                    <li>You agree that by using the Services, you shall not access, store, create, distribute or transmit any material that:
          <ol start={1} className="lower-alpha">
                        <li>is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</li>
                        <li>facilitates illegal activity;</li>
                        <li>depicts sexually explicit images;</li>
                        <li>promotes unlawful violence;</li>
                        <li>is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability, or any other illegal activity;</li>
                        <li>causes damage or injury to any person or property; or</li>
                        <li>may give rise to a claim or regulatory action for intellectual property infringement, misleading advertising or unfair competition,</li>
                      </ol>
          and we reserve the right, without liability to you, to disable your access to the Services and the Platform if you store, processes, use or in any other way have any involvement with any material that breaches the provisions of this Clause.</li>
                    <li>You shall not:
          <ol start={1} className="lower-alpha">
                        <li>attempt to remove the watermark from, or improve the resolution of a Free Video, or in any way seek to use a Free Video for a commercial purpose;</li>
                        <li>attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Platform in any form or media or by any means; or</li>
                        <li>attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Platform; or</li>
                        <li>access all or any part of the Services or Platform in order to build a product or service which competes with the Services or Platform; or</li>
                        <li>use the Services or Platform to provide services to third parties; or</li>
                        <li>license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services or Platform available to any third party except the Authorised Users, or</li>
                        <li>attempt to obtain, or assist third parties in obtaining access to the Services or Platform other than as provided under this Clause.</li>
                      </ol>
                    </li>
                    <li>You shall prevent unauthorised access to the Platform, including without limitation by protecting your passwords and other log-in information. You shall notify us immediately of any known or suspected unauthorised use of Platform or breach of security and you shall use your best endeavours to mitigate the risk of said breach.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Proprietary Rights</span>
                  <ol start={1} className="list-dec">
                    <li>You acknowledge and agree that Edit Square and/or its licensors own all intellectual property rights in the Services, the Platform and any Platform Content. Except as expressly stated herein, these Terms do not grant you any rights to, or in, patents, copyrights, database rights, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences in respect of the Services, the Platform or any Platform Content.</li>
                    <li>We confirm that we have the right to use the Platform and the Platform Content in conjunction with the Services.</li>
                    <li>We acknowledge and agree that you shall own all rights, title and interest in and to the Client Content. We shall only use the Client Content in accordance with the conditions of these Terms. Except with your prior permission, we shall not make any Client Content available or visible to other users of the Platform.</li>
                    <li>You confirm that you have the right to use the Client Content in conjunction with the Services. You hereby warrant, represent and undertake that the Client Content is your original creation (or you have the right to use the Client Content) and that no part of the Client Content has been copied from any other work or material.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Confidentiality</span>
                  <ol start={1} className="list-dec">
                    <li>Each Party may be given access to Confidential Information from the other Party in order to perform its obligations under these Terms. Confidential Information shall not include information that:
          <ol start={1} className="lower-alpha">
                        <li>is or becomes publicly known other than through any act or omission of the receiving Party;</li>
                        <li>was in the other Party’s lawful possession before the disclosure;</li>
                        <li>is lawfully disclosed to the receiving Party by a third party without restriction on disclosure;</li>
                        <li>is independently developed by the receiving Party, which independent development can be shown by written evidence; or</li>
                        <li>is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.</li>
                      </ol>
                    </li>
                    <li>This Clause 10 shall survive expiry or termination of these Terms with you.</li>
                    <li>Each Party shall hold the other’s Confidential Information in confidence and, unless required by law, not make the other’s Confidential Information available to any third party, or use the other’s Confidential Information for any purpose other than the performance of these Terms.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Indemnity</span>
                  <ol start={1} className="list-dec">
                    <li>You shall defend, indemnify and hold harmless Edit Square against claims, actions, proceedings, losses, damages, expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in connection with:
          <ol start={1} className="lower-alpha">
                        <li>any breach of your obligations under Clauses 8 and 9.4 above;</li>
                        <li>any Authorised Users’ use of the Services or Platform in violation of these Terms.</li>
                        <li>your use of the Services or Platform in violation of these Terms; or</li>
                      </ol>
                    </li>
                    <li>This Clause 11 shall survive expiry or termination of these Terms with you.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Limitation of Liability</span>
                  <ol start={1} className="list-dec">
                    <li>Edit Square’s total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of the Services under these Terms shall be limited to the total Fees paid by you in the twelve (12) month period immediately preceding the date on which the loss arose.</li>
                    <li>In no event shall Edit Square, its employees, agents and sub-contractors be liable to you for any losses arising where:
          <ol start={1} className="lower-alpha">
                        <li>a modification is made to the Services or the Platform by anyone other than us; or</li>
                        <li>your use of the Services or the Platform is conducted in a manner contrary to the instructions given to you by us; or</li>
                        <li>you continue to use the Platform after notice of an alleged or actual infringement from us or any appropriate authority.</li>
                      </ol>
                    </li>
                    <li>Except as expressly and specifically provided in these Terms:
          <ol start={1} className="lower-alpha">
                        <li>you assume sole responsibility for videos produced from your use of the Services and the Platform, and for any further publication of the same. Edit Square makes no warranty that the Platform or Services will meet your requirements;</li>
                        <li>all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from these Terms and we make no representations or warranties of any kind, whether express or implied, as to the operation of the Platform or the Services, including, but not limited to, any warranties of merchantability, fitness for purpose, and accuracy; and</li>
                        <li>the Platform and the Services are provided to you on an “as is” and “as available” basis and Edit Square makes no warranty that the Platform and Services will be uninterrupted, timely, secure, or error-free.</li>
                      </ol>
                    </li>
                    <li>Edit Square shall not be liable to you for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under these Terms.</li>
                    <li>No advice or information, whether oral or written, obtained by you from us through the Platform or the Services or otherwise will create any warranty, representation or guarantee not expressly stated in these Terms.</li>
                    <li>Notwithstanding any other element of these Terms, the liability of the parties shall not be limited in any way in respect of death or personal injury caused by negligence, fraud or fraudulent misrepresentation or any other losses which cannot be excluded or limited by applicable law.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">Termination</span>
                  <ol start={1} className="list-dec">
                    <li>We may terminate these Terms with you:
          <ol start={1} className="lower-alpha">
                        <li>with immediate effect by written notice if you commit an irremediable material breach of these Terms;</li>
                        <li>by providing written notice if you are in material breach of these Terms and you fail to cure that breach within thirty (30) days after receipt of such written notice; or</li>
                        <li>you cease your business operations, go into voluntary insolvency or becomes subject to insolvency or bankruptcy proceedings.</li>
                      </ol>
                    </li>
                    <li>If you opt for a Subscription Plan, you may terminate the Subscription at any time by providing us with written notice or via your account before we take payment for the next Subscription period. We recommend giving at least 3 (three) Working Days’ notice before the end of the current Subscription period to ensure payment for the next Subscription period can be stopped. We cannot offer refunds where insufficient notice is provided to us to terminate.</li>
                    <li>If you opt for the Pay-per-video payment plan, you may stop using Services at any time.</li>
                    <li>If these Terms are terminated between you and us:
          <ol start={1} className="lower-alpha">
                        <li>you shall not be entitled to any refund of any Fees unless we expressly agree otherwise;</li>
                        <li>the licence to use the Platform set out in Clause 5.1 and all other rights granted by Edit Square to you pursuant to these Terms shall cease immediately;</li>
                        <li>the licence to use the Client Content set out in Clause 5.3 shall cease immediately;</li>
                        <li>we shall deactivate your account with us and you will lose access to all Free Videos, Paid Videos and any Client Content contained in such videos stored on the Platform; and</li>
                        <li>each Party shall return and make no further use of any materials and other items (and all copies of them) belonging to the other Party.</li>
                      </ol>
                    </li>
                    <li>Clauses 4, 5.2, 6, 8, 9, 10, 11, 12, 13.4 and 14 shall survive expiration or termination of these Terms.</li>
                  </ol>
                </li>
                <li><span className="font-bold text-lg">General</span>
                  <ol start={1} className="list-dec">
                    <li>Neither Party shall have liability to the other Party under these Terms if it is prevented from or delayed in performing its obligations under these Terms, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes, failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of application or sub-contractors, provided that the other Party is notified of such an event and its expected duration.</li>
                    <li>No delay, neglect or forbearance on the part of either Party in enforcing any condition of these Terms shall be or be deemed to be a waiver of, or otherwise prejudice, any right of that Party under these Terms.</li>
                    <li>If any provision (or part of a provision) of these Terms is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.</li>
                    <li>You shall not, without the prior written consent of Edit Square, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under these Terms. Edit Square may assign all or any of its rights or obligations under these Terms, but it shall remain responsible for the performance of any obligations to you following such assignment.</li>
                    <li>Nothing in these Terms is intended to or shall operate to create a partnership between the Parties, or authorise either Party to act as agent for the other, and neither Party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).</li>
                    <li>These Terms do not confer any rights on any person or party other than the Parties to these terms and, where applicable, their successors and permitted assigns.</li>
                    <li>Any notice required to be given under these Terms shall be in writing and shall be delivered by hand, sent via first class registered delivery or e-mail to the other Party. A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business hours, at 9 am on the first Working Day following delivery). A notice delivered by first class registered delivery shall be deemed to have been received two (2) days after it as sent. A notice delivered by email shall be deemed delivered on the date it was sent.</li>
                    <li>These Terms and any disputes or claims arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) are governed by, and construed in accordance with, the laws of England and Wales.</li>
                    <li>The parties irrevocably agree that the courts of England and Wales have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms or its subject matter or formation (including non-contractual disputes or claims).</li>
                    <li>We may need to modify these Terms from time-to-time as our Services develop and to accommodate changes in the law. We suggest you regularly check these Terms as published on our website. Your use of the Services after a change to the Terms shall amount to your acceptance of such change.</li>
                    <li>These Terms, and any documents referred to in them, constitute the whole agreement between the Parties and supersede any previous arrangement, understanding or agreement between them relating to the subject matter they cover.</li>
                    <li>Each of the Parties acknowledges and agrees that in entering into these Terms it does not rely on any undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or not) of any person (whether party to these Terms or not) relating to the subject matter of these Terms, other than as expressly set out in these Terms.</li>
                  </ol>
                </li>
              </ol>
              <hr />
              <span className="font-bold text-lg mb-3 d-block">Schedule – Data Processing Addendum
  </span>
              <ol start={1} className="list-dec">
                <li>The Parties agree that, for the Client Data, you shall be the Data Controller and we shall be the Data Processor.&nbsp;You acknowledge and agree that you are responsible for obtaining consent from any relevant Data Subjects to process their personal data if contained in the Client Data (or otherwise justifying the legal basis for such processing). You hereby instruct Edit Square as a Data Processor to process this Client Data on behalf of you for the provision of the Services.</li>
                <li>We shall process Client Data in compliance with&nbsp;the obligations of Data Processors under Data Protection Laws in respect of the performance of its obligations under these Terms.&nbsp;You shall comply with&nbsp;all Data Protection Laws in connection with the processing of Client Data, the Services and the exercise and performance of its respective rights and obligations under these Terms, including maintaining all relevant regulatory registrations and notifications as required under Data Protection Laws.&nbsp;You warrant, represent and undertake, that:
      <ol start={1} className="lower-alpha">
                    <li>all Client Data sourced by you for use in connection with the Services shall comply in all respects with Data Protection Laws;</li>
                    <li>all instructions given by you to us in respect of Client Data shall at all times be in accordance with Data Protection Laws.</li>
                  </ol>
                </li>
                <li>Insofar as we process Client Data on behalf of you, we:
      <ol start={1} className="lower-alpha">
                    <li>unless required to do otherwise by Applicable Law, shall process the Client Data only on and in accordance with your documented instructions (the “Processing Instructions”);</li>
                    <li>if Applicable Law requires us to process Client Data other than in accordance with the Processing Instructions, we shall notify you of any such requirement before processing the Client Data (unless Applicable Law prohibits such information on important grounds of public interest); and</li>
                    <li>shall promptly inform you if Edit Square becomes aware of a Processing Instruction that, in our opinion, infringes Data Protection Laws.</li>
                  </ol>
                </li>
                <li>We shall implement and maintain, at our cost and expense, reasonable technical and organisational measures&nbsp;in relation to the processing and security of Client Data by us.</li>
                <li>Except as disclosed in the existing Sub-Processor table below, we shall not engage any Sub-Processor for carrying out any processing activities in respect of the Client Data without your written authorisation relating to that specific Sub-Processor.</li>
                <li>We shall refer all Data Subject Requests it receives to you. We shall provide reasonable assistance to you with such Data Subject Requests.&nbsp;We shall provide such reasonable assistance as you may reasonably require to you in ensuring compliance with your obligations under Data Protection Laws, provided you shall pay our reasonable expenses for providing the assistance in this Clause 6.</li>
                <li>You agree that we may transfer Client Data to countries outside the European Economic Area (the “EEA”) or to any International Organisation(s), provided all such transfers shall (to the extent required under Data Protection Laws) be effected by way of Appropriate Safeguards and in accordance with Data Protection Laws.</li>
                <li>In respect of any Personal Data Breach involving Client Data, we shall, without undue delay:
      <ol start={1} className="lower-alpha">
                    <li>notify you of the Personal Data Breach; and</li>
                    <li>provide you with details of the Personal Data Breach.</li>
                  </ol>
                </li>
                <li>We shall, at your written request, either delete or return all the Client Data to you in such form as you reasonably request within a reasonable time after the earlier of:
      <ol start={1} className="lower-alpha">
                    <li>the termination of the Terms with you; or</li>
                    <li>once processing by us of any Client Data is no longer required for the purpose of the performance of our relevant obligations under these Terms,</li>
                  </ol>
      and delete existing copies (unless storage of any data is required by Applicable Law and, if so, we shall inform you of any such requirement).</li>
                <li>The following definitions shall apply to the provisions of this Schedule.
      <table className="table table-sm table-bordered mt-3 mb-4">
                    <tbody>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Applicable Law</td>
                        <td colSpan={1} rowSpan={1}>
                          means as applicable and binding on the Parties and/or the Services under these Terms:
              <ol start={1} className="lower-alpha">
                            <li>any law, statute, regulation, byelaw or subordinate legislation in force from time to time to which a party is subject and/or in any jurisdiction that the services under these Terms are provided to or in respect of;</li>
                            <li>the common law and laws of equity as applicable to the Parties from time to time;</li>
                            <li>any binding court order, judgment or decree; or</li>
                            <li>any applicable direction, policy, rule or order that is binding on a Party and that is made or given by any regulatory body having jurisdiction over a Party or any of that Party’s assets, resources or business;</li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Appropriate Safeguards</td>
                        <td colSpan={1} rowSpan={1}>
                          means such legally enforceable mechanism(s) for transfers of Personal Data as may be permitted under Data Protection Laws from time to time;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Data Controller, Data Processor, Data Subject, Personal Data, processing</td>
                        <td colSpan={1} rowSpan={1}>
                          shall all have the meaning given to that term in Data Protection Laws;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Data Protection Laws</td>
                        <td colSpan={1} rowSpan={1}>
                          means the Data Protection Act 2018, the GDPR and/or any Applicable Laws replacing, amending, extending, re-enacting or consolidating any of the above from time to time;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Data Protection Losses</td>
                        <td colSpan={1} rowSpan={1}>
                          means all liabilities, including all:
              <ol start={5} className="lower-alpha">
                            <li>costs (including legal costs), claims, demands, actions, settlements, interest, charges, procedures, expenses, losses and damages (including relating to material or non-material damage); and</li>
                            <li>to the extent permitted by Applicable Law:
                  <ol start={1} className="lower-roman">
                                <li>administrative fines, penalties, sanctions, liabilities or other remedies imposed by a Supervisory Authority;</li>
                                <li>compensation which is ordered by a Supervisory Authority to be paid to a Data Subject; and</li>
                                <li>the reasonable&nbsp;costs of compliance with investigations by a Supervisory Authority;</li>
                              </ol>
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Data Subject Request</td>
                        <td colSpan={1} rowSpan={1}>
                          means a request made by a Data Subject to exercise any rights of Data Subjects under Data Protection Laws;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>GDPR</td>
                        <td colSpan={1} rowSpan={1}>
                          means the General Data Protection Regulation&nbsp;(EU)&nbsp;2016/679;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>International Organisation</td>
                        <td colSpan={1} rowSpan={1}>
                          means an organisation and its subordinate bodies governed by public international law, or any other body which is set up by, or on the basis of, an agreement between two or more countries;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Personal Data Breach</td>
                        <td colSpan={1} rowSpan={1}>
                          means any breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, any Client Data;</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Sub-Processor</td>
                        <td colSpan={1} rowSpan={1}>
                          means another Data Processor engaged by us for carrying out processing activities in respect of the Client Data on your behalf; and</td>
                      </tr>
                      <tr>
                        <td colSpan={1} rowSpan={1}>Supervisory Authority</td>
                        <td colSpan={1} rowSpan={1}>
                          means any local, national or multinational agency, department, official, parliament, public or statutory person or any government or professional body, regulatory or supervisory authority, board or other body responsible for administering Data Protection Laws.</td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ol>
              <u>Existing Sub-Processors</u>
              <ul className="list-group">
                <li>Paddle</li>
                <li>Google Analytics</li>
                <li>GMail / GSuite (Google)</li>
                <li>Graph CMS</li>
                <li>Uploadcare</li>
                <li>Amazon Web Services</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default Terms;
